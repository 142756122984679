/* Styling for the background */
.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f56a79, #ffba75);
}

/* Animation for the "Coming Soon" text */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Styling for the "Coming Soon" text */
.coming-soon-text {
  font-family: "Arial Black", sans-serif;
  font-size: 3rem;
  color: white;
  font-weight: bold;
  animation: bounce 2s infinite;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}
